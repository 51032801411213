import React, { useState } from 'react';
import { Typography, Radio, Form, Input, Button } from 'antd';
import CtaSecondary from '../components/Buttons/secondary-cta';
import { viewports } from '../style-vars';
import logo from '../images/global_assets/SpotOn-Logo.png';
import loginIll from '../images/global_assets/login-illustation.png';
import chevron from '../images/svg/chevron_left.svg';
import help from '../images/subvertl-images/help.png';

const { Title } = Typography;
const { mdOrSmaller, smOrSmaller } = viewports;

const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    type: 'merchant',
  });
  const onFinish = (values) => {
    const formData = {
      fields: [
        {
          name: 'email',
          value: values.email,
        },
        {
          name: 'password',
          value: values.password,
        },
      ],
    };
  };
  return (
    <section className="login">
      <img src={logo} className="login__logo" alt="" />
      <Form
        className="login__form-container"
        onFinish={onFinish}
        layout="vertical"
        name="Login Form"
      >
        <Title className="text-center">
          Welcome <span className="">.</span>
        </Title>
        <p className="text-center">Log into your account</p>
        <Radio.Group defaultValue="merchant" buttonStyle="solid">
          <Radio.Button value="merchant">Merchant</Radio.Button>
          <Radio.Button value="ae">Account Executive</Radio.Button>
        </Radio.Group>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please enter your email',
              min: 2,
            },
          ]}
        >
          <Input size="large" placeholder="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
          ]}
        >
          <Input.Password size="large" placeholder="password" />
        </Form.Item>
        <div className="form-grid">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="cta-primary"
              style={{ marginTop: 20 }}
              size="small"
            >
              Login
              <img
                src={chevron}
                alt="chevron icon"
                style={{ margin: '5px 5px 2px 10px' }}
              />
            </Button>
          </Form.Item>
          <CtaSecondary target="/page-2" ctaTitle="Forgot Password?" />
        </div>
      </Form>
      <div className="login-foot">
        <p>
          <img src={help} alt="" />
          If you are having problems, please <a href="#">contact us.</a>
        </p>
      </div>
      <img src={loginIll} className="login__ill" alt="" />

      <style jsx global>{`
        .login {
          position: relative;
          padding: 64px;
          height: 100vh;
          font-family: 'sofia-pro', sans-serif !important;
        }
        @media ${mdOrSmaller} {
          .login {
            padding: 32px;
          }
        }
        .login input {
          border-radius: 4px !important;
        }
        .login-foot {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          border-top: 1px solid #bfc6d0;
          padding: 32px 0;
        }
        .login-foot img {
          width: 32px;
          margin-right: 8px;
        }
        .login .ant-input-affix-wrapper {
          border-radius: 4px;
        }
        .login .form-grid {
          display: grid;
          grid-template-columns: 1fr;
          justify-content: center;
        }
        .login__logo {
          width: 150px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
        .login__form-container {
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07),
            20px 10px 40px rgba(47, 80, 167, 0.1);
          padding: 24px 78px;
          width: 40%;
          position: absolute;
          left: 50%;
          top: 15%;
          transform: translateX(-50%);
          border-radius: 12px;
          z-index: 1;
          background: #fff;
        }
        @media ${mdOrSmaller} {
          .login__form-container {
            position: relative;
            width: 100% !important;
          }
        }
        .login__ill {
          width: 450px;
          position: absolute;
          bottom: 0;
          z-index: -1;
        }
        @media ${smOrSmaller} {
          .login__ill {
            display: none;
          }
        }
        .login .ant-radio-button-wrapper {
          border-radius: 26px;
          margin: 8px 8px 8px 8px;
          border: none;
          background: transparent;
          box-shadow: none !important;
        }
        .login .ant-radio-button-wrapper:before {
          display: none;
        }
        .login .ant-radio-group-solid {
          margin: 32px auto !important;
          display: table;
          border-radius: 36px;
          background: rgba(116, 128, 147, 0.1);
          font-weight: 800 !important;
        }
        .login .cta-primary {
          margin: 0 50%;
          transform: translateX(-50%);
          padding: 24px 64px !important;
          font-size: 16px !important;
          font-weight: 800;
        }
        .login .cta-secondary {
          margin-bottom: 32px;
        }
        .login .cta-secondary span {
          font-weight: 400 !important;
        }
      `}</style>
    </section>
  );
};

export default Login;
